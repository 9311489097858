<template>
  <div class="video-container">
    <!-- src="https://www.youtube.com/embed/YZIaaEt-9Ds" -->
    <iframe width="560" height="315" :src="ytSrc" title="YouTube video player" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen />
  </div>
</template>

<script>

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  computed: {
    ytSrc() {
      const prefix = [/^https:\/\/youtu.be\//, /^https:\/\/www.youtube.com\/watch\?v=/];
      const targetReg = prefix.find((reg) => reg.test(this.src));
      if (targetReg) return this.src.replace(targetReg, 'https://www.youtube.com/embed/');
      return this.src;
    }
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";

.video-container {
  margin-top: 30px;
  position: relative;
  height: 0;
  padding-top: 30px;
  padding-bottom: 56.25%;
  margin-bottom: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    @include position(tl, 0, 0);
    width: 100%;
    height: 100%;
  }
}
</style>
