<template>
  <div class="clinic-intro-detail">
    <div class="banner">
      <swiper :options="swiperOption" v-if="showClinicDetailData">
        <swiper-slide v-for="(pic, key) in clinicDetail.carousel" :key="key">
          <figure class="banner__image" :style="{ backgroundImage: `url(${pic})` }"></figure>
        </swiper-slide>
      </swiper>
      <div v-else class="banner__image" :style="{ backgroundImage: `url(${clinicDetail.carousel[0]})` }"></div>
    </div>

    <section class="description w1400">

      <h5 class="title txt-bold">
        {{ this.clinicDetail.title }}
        <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower" />
      </h5>

      <div class="description-wrap">
        <div class="description-wrap__img-wrap">

          <img :src="clinicDetail.cover_path" />
        </div>

        <div class="description-wrap__text">
          <h4>{{ clinicDetail.description }}</h4>
          <div v-html="clinicDetail.content"></div>
        </div>
      </div>

      <div class="youtube-wrap">
        <Youtube :src="clinicDetail.ytUrl" />
      </div>
    </section>

  </div>
</template>

<script>
import imageSource from '@/utils/imageSource';
import { mapState } from 'vuex';
import Youtube from '@/components/global/Youtube.vue';

export default {
  name: 'ClinicIntroDetail',
  components: {
    Youtube
  },
  data() {
    return {
      swiperOption: {
        autoplay: true,
        speed: 1000,
        delay: 8000,
        // loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        pagination: {
          el: '#location .swiper-pagination',
          clickable: true
        }
      },
    };
  },
  computed: {
    ...mapState(['articles']),
    clinicDetail() {
      const matchClinic = this.articles.find(({ uuid }) => uuid === this.$route.params.uuid);

      if (!matchClinic) {
        return ({});
      }

      return ({
        ...matchClinic,
        carousel: matchClinic.data.images.map((imagePath) => imageSource(imagePath)).filter((item) => item !== null), // 濾掉沒有的圖
        ytUrl: matchClinic.data?.video_url
      });
    },
    showClinicDetailData() {
      if (!this.clinicDetail.carousel || this.clinicDetail.carousel.length <= 1) {
        return false;
      }

      return this.clinicDetail.carousel.length > 0;
    }
  },
};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.clinic-intro-detail {
  background-color: #F8F8F8;
}

.banner {
  &__image {
    height: 400px;
    background-color: #ccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.description {
  @include padding(40px 0);
}

.title {
  text-align: center
}

.description-wrap {
  margin-top: 40px;

  &__text {
    margin-top: 40px;

  }
}

.youtube-wrap {
  margin-top: 80px;
}

@media screen and (min-width: 1024px) {
  .banner {
    &__image {
      height: 500px;
    }
  }

  .description {
    @include padding(80px 0);
  }

  .description-wrap {
    @include flex(space-between, flex-start);
    margin-top: 80px;

    &__img-wrap {
      flex: none;
      width: 50%;
      background-color: $c-white;
      @include padding(20px);

    }

    &__text {
      flex: none;
      width: 50%;
      margin-left: 40px;
    }
  }

}
</style>
